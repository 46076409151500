@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

:root {
  --primary-color: #042d5a;
  --secondary-color: #596780;
  --accent-color: #0080ff;
  --hero-background: #eef6ff;
  --black: #2a3037;
  --white: #ffffff;
  --padding: 4vw;
}

@layer components {
  .btn-form-primary {
    @apply px-[4rem] py-[1.25rem] !bg-primary w-fit mt-[30px] rounded-lg ml-auto text-white;
  }

  .btn-form-primary:disabled::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid;
    border-top-color: white;
    -webkit-animation: loader 0.8s linear infinite;
    animation: loader 0.8s linear infinite;
  }

  .btn-form-primary:disabled {
    position: relative;
    color: var(--primary-color) !important;
  }

  .buyBtn:disabled::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid;
    border-top-color: white;
    -webkit-animation: loader 0.8s linear infinite;
    animation: loader 0.8s linear infinite;
  }

  .buyBtn:disabled {
    position: relative;
    color: var(--primary-color) !important;
  }

  .btn-form-neutral {
    @apply px-[4rem] py-[1.25rem] border border-darkGrey bg-transparent w-fit mt-[30px] rounded-lg ml-auto text-black;
  }
}

/* .test {
	position: fixed !important;
	top: 0;
	width: 100%;

}

#test {
	overflow: hidden;
	background-color: #fff;
} */
.scrollmenot {
  overflow: scroll;

  scrollbar-width: thin !important;

  scrollbar-color: transparent transparent !important;

  -webkit-overflow-scrolling: touch;
  /* For smooth scrolling on iOS devices */

  /* Hide the scroll bar */
}

.landing-img {
  position: absolute;
  bottom: 0;
  right: 0;
  /* float: right; */
}

.scrollmenot::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.the-header {
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 1000;
}

.upload-file {
  align-items: center;
  border: 1px solid #d7d7d7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
}

.upload-btn {
  margin-bottom: 10px;
  background-color: #fafafa;
  border: none;
  border-radius: 6px;
  padding: 10px 15px;
  border: 1px solid #ccc;
}

a:hover {
  color: var(--primary-color);
}

#root {
  margin-inline: auto;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

input:focus-visible {
  outline: none;
}

button:focus {
  outline: none;
}

.background_banner {
  background: url("assets/student-side-img.png") center no-repeat;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
}

.background_banner1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
  transform: translateX(22%);
  z-index: -1;
  opacity: 0.8;
}

.submit-signup:disabled::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid;
  border-top-color: white;
  -webkit-animation: loader 0.8s linear infinite;
  animation: loader 0.8s linear infinite;
}

.submit-signup:disabled {
  position: relative;
  color: var(--primary-color) !important;
}

.react-tabs__tab-list {
  overflow-x: hidden !important;
}

.card-img {
  width: 100%;
}

.courses-card-img {
  width: 25%;
  max-width: 300px;
  max-height: 250px;
  min-width: 180px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.color-primary {
  color: var(--primary-color);
}

.color-accent {
  color: var(--accent-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5% !important;
}

.courseReview {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 32px;
  color: #344054;
}

.courseReviewRatings {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #344054;
  margin: 20px;
}

.courseReviewTextarea {
  box-sizing: border-box;
  padding: 15px;
  gap: 10px;
  width: 100%;
  border: 1px solid #dcdee1;
  border-radius: 5px;
  font-size: 1.4rem;
  color: #98a2b3;
  margin-top: 20px;
}

.modal {
  margin-top: 50px;
  height: 800px;
}

.app-modal-content {
  border-radius: 10px;
  padding: 30px;
  background: #fff;
  border: none;
  margin: 0 auto;
}

.modal-header {
  border-bottom: none;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.4rem;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  padding-inline: 0 !important;
}

.modal-header::before,
.modal-header::after {
  display: none;
}

.modal-header h4.modal-title {
  font-size: 1.6rem;
  color: var(--primary-color);
}

.modal-header .close {
  padding: 10px;
  background-color: transparent;
  border: none;
  font-size: 2rem;
}

.rec-modal-body {
  padding: 0;
}

.modal-xlg {
  width: 1200px !important;
  max-width: 1200px;
}

.modal-lg {
  width: 900px !important;
  max-width: 900px;
}

.modal-normal {
  width: 500px !important;
  max-width: 900px;
}

.modal-xsm {
  width: 400px !important;
  max-width: 400px;
}

.modal-xsm .modal-title {
  font-size: 0.5rem;
}

.modal-subtitle {
  margin-bottom: 30px;
  margin-top: 0px;
  font-size: 1.4rem;
  font-family: "Plus Jakarta Display";
  color: #5e6c85;
}

.modal-dialog {
  max-width: 90vw;
}

.ReactModal__Overlay--after-open {
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7) !important;
}

.ReactModal__Content--after-open {
  border: none !important;
  background: transparent !important;
  inset: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-nav {
  display: none;
}

.courses-you-need {
  font-family: "Plus Jakarta Display";
  font-size: 48px;
  font-weight: 500;
  color: #fff;
  padding: 30px;
  max-width: 679px;
  line-height: 62.4px;
  margin-top: 100px;
}

.courses-you-need-sub {
  font-family: "Plus Jakarta Display";
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  padding-left: 30px;
  max-width: 551px;
  line-height: 30.7px;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 56.25% !important;
  }
}

@media screen and (max-width: 456px) {
  html {
    font-size: 56.25% !important;
  }
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

ul,
ol {
  list-style: initial !important;
  list-style-position: inside !important;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 1.6rem;
  font-family: "Plus Jakarta Sans", sans-serif;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a:hover {
  text-decoration: none;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.app-footer {
  background-color: var(--primary-color);
  color: white;
  margin-top: 50px;
  display: flex;
}

.app-footer a:hover {
  color: inherit;
}

.footer-div {
  max-width: 1440px;
  padding: calc(var(--padding) / 2) calc(var(--padding) * (3 / 4));
  justify-self: center;
  width: 100%;
  margin-inline: auto;
}

.accordion__button {
  padding: 1rem 0rem !important;
  border-bottom: none !important;
  /* border-top: none !important; */
}

.accordion__button:hover {
  background-color: transparent !important;
}

.accordion__item + .accordion__item {
  border-top: none !important;
}

.accordion__item {
  margin-bottom: 10px;
}

.courses-card {
  max-width: 100%;
}

.search-page {
  display: grid;
  grid-template-columns: 2fr 7fr;
  gap: 15px;
}

.courses-contain {
  width: 70%;
}

.sidenav {
  width: 30%;
}

.a-card-title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: Plus Jakarta Display;
  color: #2a3037;
  text-transform: capitalize;
}

.a-card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 24px;
  color: #5e6c85;
  margin: 5px 0;
}

.a-card-text {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 18.2px;
  margin-bottom: 0.5rem;
  font-family: Plus Jakarta Display;
  max-width: 506px;
  color: #5e6c85;
}

.a-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.a-card-body {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-left: 10px;
}

.rating-number {
  margin-right: 7px;
  font-size: 1.2rem;
}

.card-container {
  display: flex;
  border-bottom: 1px solid rgb(241 241 241);
  padding-bottom: 25px;
}

.course-author {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: "plus jakarta display";
  margin-top: 7px;
  margin-bottom: 7px;
}

/* .old-price {
	text-decoration: line-through
}

.new-price {
	text-decoration: blink !important
} */
.buy-course-banner {
  display: flex;
  justify-content: space-between;
}

.buy-course-heading {
  max-width: 80%;
  font-size: 3.2rem;
  font-family: plus jakarta display;
  color: var(--primary-color);
  font-weight: 700;
  margin-bottom: 10px;
}

.buy-course-subheading {
  /* max-width: 492px; */
  font-size: 2rem;
  font-family: plus jakarta display;
  color: #5e6c85;
  font-weight: 400;
  margin-bottom: 10px;
}

.courses-accordion {
  border-right: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.control-form {
  display: block;
  width: 100%;
  height: 48px;
  padding: 6px 12px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f2f4f7;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-check-input {
  margin-top: 0.5rem;
}

.has-pwd-icon {
  position: relative;
}

/* popup for custom popup component */
.popup-content {
  padding-block: 2px;
  background-color: white;
  color: #45464e;
  width: 200px !important;
  border: 0.5px solid #e1e2e9;
  border-radius: 4px;
}

.popup-option {
  padding: 3px 15px;
  font-weight: 400;
  font-size: 1.4rem;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 5px;
  align-items: center;
}

/* .popup-option:hover {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  cursor: pointer;
} */

.view-pwd {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 15px;
  color: #8a8383;
  cursor: pointer;
}

.custom-success-popup {
  background: #d1ecdd !important;
  border-left: 3px solid #1aa053;
  padding: 10px !important;
  border-radius: 0px;
}

.custom-popup-title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem !important;
  line-height: 130%;
}

.custom-success-popup .custom-popup-title {
  color: #12703a;
}

.custom-error-popup {
  background: #f2d6d3 !important;
  border-left: 3px solid #c03221;
  border-radius: 0px;
}

.custom-error-popup .custom-popup-title {
  color: #862317;
}

.custom-popup-icon {
  border: none !important;
  margin: 0 !important;
}

.custom-popup-icon svg {
  width: 30px;
  height: 30px;
}
.custom-modal-title {
  font-size: 2.7rem;
}
.swal2-html-container {
  font-size: 1.7rem !important;
  font-weight: 500 !important;
}
.custom-modal-button {
  background-color: var(--accent-color) !important;
  padding: 7px 1.7rem;
  font-size: 1.5rem !important;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 35px;
  height: 35px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 2px solid;
  border-top-color: rgba(4, 45, 90, 0.65);
  border-bottom-color: rgba(4, 45, 90, 0.15);
  border-left-color: rgba(4, 45, 90, 0.65);
  border-right-color: rgba(4, 45, 90, 0.15);
  -webkit-animation: loader 0.8s linear infinite;
  animation: loader 0.8s linear infinite;
}

.nav-search-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 25px;
  gap: 10px;
  width: 600px;
  height: 48px;
  background: #ffffff;
  font-size: 1.4rem;
  border: 1px solid #e7e5ea;
  border-radius: 30px;
}

/* .nav-search-input::placeholder { */
/* font-size: 1.4rem; */
/* } */
.nav-search-input:focus {
  border: 1px solid var(--accent-color);
  outline: none;
}

.truncate-div::after {
  content: "";
  inset: auto 0 0 0;
  background-color: red;
}

.classcube-for-business {
  font-family: "plus jakarta display";
  font-size: 40px;
  font-weight: 700;
  color: #2a3037;
  margin-bottom: 20px;
  line-height: 52px;
  /* background-color: transparent !important; */
}

.classcube-for-business-sub {
  font-family: "plus jakarta display";
  font-size: 16px;
  font-weight: 400;
  color: #5e6c85;
  max-width: 600px;
  line-height: 30.4px;
}

.faq-header {
  font-family: "plus jakarta display";
  font-size: 28px;
  font-weight: 700;
  color: #2a3037;
  line-height: 36.4px;
  text-align: center;
  margin-bottom: 50px;
}

.question-heading {
  font-family: "plus jakarta display";
  font-size: 20px;
  font-weight: 500;
  color: #47505b;
  line-height: 32px;
  letter-spacing: -1%;
}

.faq-bg {
  background: url("assets/faqbg.png") center no-repeat;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
  margin-bottom: -50px !important;
}

.question-subheading {
  font-family: "plus jakarta display";
  font-size: 18px;
  font-weight: 500;
  color: #5e6c85;
  line-height: 27.99px;
  letter-spacing: -1%;
}

.all-i-ask {
  background: url("assets/student-side-img.png") center no-repeat;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
}

.all-i-ask2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80%;
  transform: translateX(22%);
  /* z-index: -1; */
  opacity: 0.8;
}

.explore-link {
  border: solid 1px gray;
  transition: all 0.2s;
}
.explore-link:hover {
  background-color: #042d5a;
  color: #fff;
}

.popup-content {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 33px 5px rgb(22 49 96 / 19%);
  margin: 10px;
}

.popup-option {
  padding: 8px 15px;
  font-weight: 400;
  font-size: 1.4rem;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 5px;
  align-items: center;
}

/* .popup-option:hover { */
/* background-color: var(--primary-color); */
/* backdrop-filter: blur(10px); */
/* cursor: pointer; */
/* color: #fff; */
/* } */

.product-tab {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  margin-right: 10px;
  transition: background-color 0.3s ease-in-out;
}

.active-tab {
  background-color: #007bff;
  color: white;
}
.mobile {
  display: none;
}

.top-nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-link {
  display: flex;
  gap: 30px;
  align-items: center;
}

.opacity-transition {
  transition: opacity 0.5s ease-in-out;
}

.privacy-list ol {
  margin-top: 20px;
  counter-reset: item;
  padding-left: 24px;
}
.product-items {
  display: none;
}

/* https://signup.schoolrevs.com/register?merchant=classcube */

@media screen and (max-width: 850px) {
  .web-nav {
    display: none;
  }
  .mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 30px 0 10px;
  }
}
@media only screen and (max-width: 1200px) {
  .nav-search-input {
    width: 400px;
    height: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .landing-img {
    display: none;
  }

  .landing-contain {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 930px) {
  .nav-search-input {
    width: 300px;
    height: 30px;
  }

  .background_banner {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .accordion {
    border-right: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  }

  .sidenav {
    width: 100%;
  }

  .our-faq {
    display: block !important;
  }
}

@media only screen and (max-width: 768px) {
  .search-page {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .card-container {
    display: block;
  }

  .courses-card-img {
    width: 100%;
    max-width: initial;
  }
}
