.pageTitle {
	font-style: normal;
	font-weight: 700;
	font-size: 3.2rem;
	line-height: 130%;
	color: #2a3037;
	margin-bottom: 20px;
}
.pageInfo {
	color: #5e6c85;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 125%;
	max-width: 800px;
	margin-bottom: 50px;
	text-align: justify;
}

.pageLink {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: var(--accent-color);
	border: 1px solid #dee2e6;
}
.pageLink {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: var(--accent-color);
	border: 1px solid #dee2e6;
}
.pagination {
	display: flex;
	justify-content: space-between;
	padding-left: 0;
	margin: 5rem auto;
	width: 80%;
	max-width: 600px;
	gap: 2rem;
	user-select: none;
}
.pagination > li {
	display: inline;
}
.pagination li:nth-child(2),
.pagination li:last-child {
	margin-left: auto;
}
.pagination > li > a,
.pagination > li > span {
	position: relative;
	float: left;
	margin-left: -1px;
	text-decoration: none;
	padding: 0 5px;
	border: none;
}
.pagination li a:hover {
	background-color: #77777720;
	padding: 0px 5px;
	border-radius: 5px;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
	z-index: 2;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
	margin-left: 0;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
	z-index: 3;
	color: #fff;
	cursor: default;
	background-color: var(--accent-color);
	border: solid 1px var(--accent-color);
	padding: 0 5px;
	border-radius: 5px;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
	color: #999;
	cursor: default;
	background-color: #999;
	border: solid 1px #999;
}
