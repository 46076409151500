.accordion {
	border: none;
	border-radius: 2px;
}

.accordion__item + .accordion__item {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
	background-color: var(--white);
	color: #444;
	cursor: pointer;
	padding: 1rem 1.5rem;
	width: 100%;
	border-bottom: 1px solid #e7e5ea;
	font-style: normal;
	font-weight: 500;
	font-size: 1.8rem;
	line-height: 120%;
	color: #000000;
}

.accordion__button:hover {
	background-color: #ddd;
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
	transform: rotate(45deg);
}

[hidden] {
	display: none;
}

.accordion__panel {
	padding: 15px;
	animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
