/* * {
  outline: solid 1px red;
} */

li {
	list-style-type: none;
}

@media screen and (min-width: 1280px) {
	.content h1 {
		width: 55%;
	}
	.content p {
		width: 58%;
	}
	.images img {
		margin-bottom: 2rem;
	}
}

a:hover {
	color: inherit;
}
button {
	cursor: pointer;
}
.gradient {
	background-image: linear-gradient(to bottom, #c4daf0de 50%, #ffffff);
}
.light-blue {
	background-color: #c4daf0de;
}
.header {
	background-image: url(../../assets/image.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.hero-img .gradient {
	display: inline-block;
	width: 63.9%;
	background-image: linear-gradient(to bottom, #ffffff3d 50%, #fff);
	height: 100%;

	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%);
}

.color-primary {
	color: #0d3060;
}
.shadow-blue {
	box-shadow: 1px 1px 1rem #0c77f162;
}
.bg-color-pry {
	background-color: #0372ce;
}
.color-secondary {
	color: #7a88a0;
}
.btn {
	border: solid 1px #0372ce;
	color: #0372ce;
	font-size: 16px;
}
.images {
	width: 70%;
}
.color-1 {
	color: #5f5f5f;
}
.text-main {
	font-size: 1.7rem;
}
.Benefit h1 {
	font-size: 2rem;
}
.feedback {
	border: solid 1px #4699f6;
	/* width: 470px; */
	height: max-content;
}
.mobile-nav {
	display: none;
}
.mobile-content {
	position: absolute;
	top: 100%;
	left: 100%;
	width: 100%;
	height: 20vh;
	background-color: #c4daf0de;
	padding: 4rem 2rem;
	transition: left 0.7s ease-in-out;
}
.mobile-content .cta {
	flex-direction: column;
	align-items: flex-start;
	gap: 3rem;
}
.open {
	left: 0%;
}
.nav-link {
	cursor: pointer;
}

/* .feats{
    background-color: red;
} */
.color-yellow {
	color: #ffc05f;
}
.bg-light-yellow {
	background-color: #ffbf5f23;
}

.color-pink {
	color: #f3464a;
}
.bg-light-pink {
	background-color: #f3464a40;
}

.bg-light-blue {
	background-color: #2583e820;
}
.feat-1 {
	background-image: linear-gradient(to bottom, #2583e82c 40%, #f5faff 100%);
	border-top: solid 1px #2582e8;
	border-left: solid 1px #2582e8;
	border-right: solid 1px #2582e8;
}

.feat-2 {
	background-image: linear-gradient(to bottom, #ffc05f40 40%, #fffbf5 100%);
	border-top: solid 1px #ffc05f;
	border-left: solid 1px #ffc05f;
	border-right: solid 1px #ffc05f;
}

.feat-3 {
	background-image: linear-gradient(to bottom, #f3464a40 40%, #fff3f3 100%);
	border-top: solid 1px #f3464a;
	border-left: solid 1px #f3464a;
	border-right: solid 1px #f3464a;
}

.Support-info {
	background-image: url("../../assets/support-bg.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.g-content {
	flex-basis: 55%;
}

.g-content li p {
	width: 80%;
}
.g-list li {
	margin-bottom: 1.5rem;
}
.g-img {
	width: 55rem;
}
.g-img img {
	width: 100%;
}
.web-nav {
	z-index: 1000000;
}
.mobile-nav {
	z-index: 1000000;
}

@media screen and (max-width: 1024px) {
	.content h1 {
		width: 100%;
	}
	.content p {
		width: 100%;
	}
	.images img {
		margin-bottom: 2rem;
	}
}
@media screen and (max-width: 800px) {
	.web-nav {
		display: none;
	}
	.mobile-nav {
		display: block;
	}
}
@media screen and (max-width: 500px) {
	.copyright {
		flex-direction: column;
		gap: 3rem;
	}
}
