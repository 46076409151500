.react-tabs {
	-webkit-tap-highlight-color: transparent;
	margin-bottom: 50px;
}

.react-tabs__tab-list {
	border-bottom: 1px solid #e7e5ea;
	margin: 0 0 10px;
	display: flex;
	overflow-x: scroll;
}

.react-tabs__tab {
	list-style: none;
	padding: 12px 15px;
	cursor: pointer;

	font-style: normal;
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 130%;
	color: #5e6c85;
	width: fit-content;
	/* min-width: 165px; */
}

.react-tabs__tab--selected {
	border-bottom: 2px solid var(--accent-color);
	color: var(--accent-color);
	outline: none;
}

.react-tabs__tab--disabled {
	color: GrayText;
	cursor: default;
}

.react-tabs__tab:focus {
	outline: none;
}

.react-tabs__tab-panel {
	display: none;
}

.react-tabs__tab-panel--selected {
	display: block;
}
