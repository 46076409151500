.react-tabs {
	-webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
	margin: 0 0 20px;
	padding: 0;
}

.react-tabs__tab {
	display: inline-block;
	position: relative;
	list-style: none;
	color: #5e6c85;
	padding: 6px 15px;
	cursor: pointer;
	/* border-bottom: 2px solid #e7e5ea; */
}

.react-tabs__tab--selected {
	border-bottom: 2px solid var(--primary-color);
	color: var(--primary-color);
	font-weight: 500;
	border-radius: 5px 5px 0 0;
}

.react-tabs__tab-panel {
	display: none;
}

.react-tabs__tab-panel--selected {
	display: block;
}
