.editor-class {
	background-color: white;
	padding: 1rem;
	border: 1px solid #ccc;
	height: 150px;
	resize: vertical;
}

.toolbar-class {
	border: 1px solid #ccc;
	background-color: transparent;
}

.editor-tooltip {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding: 10px;
	flex-direction: column;
}

.mq-root-block {
	border-color: #d28f98 !important;
	box-shadow: 0 0 0 3px #ffe0e4 !important;
	border: 1px solid transparent !important;
	border-radius: 3px !important;
}
